import { useState } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Center, Table, TableCaption, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import { OrderInterface } from './Interfaces/order-interface';
import moment from 'moment';
import { formatNumberToCurrency } from '../../util/currency';

type PropsType = {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    order: OrderInterface | null
}

enum StatusEnum {
    pending = 'PENDENTE',
    paid = 'PAGO',
    failed = 'ERRO',
    canceled = 'ESTORNADO',
}

enum PaymentTypeEnum {
    pix = 'PIX',
    credit_card = 'CARTÃO DE CRÉDITO',
}

export const InfoOrderModal = (props: PropsType) => {

    const {
      isOpen,
      setIsOpen,
      order
    } = props

    const countTotalTickets = () => {
        let quant = 0

        order?.itemsSold.map(ticket => {
            quant += ticket.quantity
        })
        
        return quant
    }

    const onClose = () => setIsOpen(false);

    return (
        <>
        <Modal
            size="6xl"
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay />
            <ModalContent>
            <ModalHeader>
                <Center><h1>INFORMAÇÕES DO PEDIDO</h1></Center> 
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>

            {order && (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                    <Table variant="striped">
                        <TableCaption>Detalhes do cliente</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>Campo</Th>
                                <Th>Informação</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>ID do Pedido</Td>
                                <Td>{order.id}</Td>
                            </Tr>
                            <Tr>
                                <Td>Valor total</Td>
                                <Td>{formatNumberToCurrency(order.amount)}</Td>
                            </Tr>
                            <Tr>
                                <Td>Quant. de ingressos comprados</Td>
                                <Td>{countTotalTickets()}</Td>
                            </Tr>
                            <Tr>
                                <Td>Cliente</Td>
                                <Td>{order.customer.name}</Td>
                            </Tr>
                            <Tr>
                                <Td>Telefone do cliente</Td>
                                <Td>{order.customer.area_code + order.customer.phone_number}</Td>
                            </Tr>
                            <Tr>
                                <Td>Status</Td>
                                <Td>{StatusEnum[order.status]}</Td>
                            </Tr>
                            <Tr>
                                <Td>Forma de pagamento</Td>
                                <Td>{PaymentTypeEnum[order.payment_type]}</Td>
                            </Tr>
                            <Tr>
                                <Td>Data</Td>
                                <Td>{moment(order.created_at).format('DD/MM/YYYY HH:mm:ss')}</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    </div>
                    
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        {order.itemsSold.map((ticket, index) => (
                            <Table border='1px' variant="unstyled">
                                <TableCaption>Detalhes dos ingressos</TableCaption>
                                    <Thead>
                                        <Tr>
                                            <Th>Campo</Th>
                                            <Th>Informação</Th>
                                        </Tr>
                                    </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>ID do ingresso</Td>
                                        <Td>{ticket.product_id}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Nome do(s) ingresso(s)</Td>
                                        <Td>{ticket.name}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Valor total do(s) ingresso(s)</Td>
                                        <Td>{formatNumberToCurrency(ticket.amount)}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Valor unitário do ingresso</Td>
                                        <Td>{formatNumberToCurrency(ticket.unit_value)}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Quant. vendidos</Td>
                                        <Td>{ticket.quantity}</Td>
                                    </Tr>
                                    <Tr>
                                        <Td>Evento</Td>
                                        <Td>{ticket.event.title}</Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                        ))}
                    </div>
                </div>
            )}
            </ModalBody>

            <ModalFooter>
                <Button onClick={onClose}>Sair</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    );
}
