import React, { useState } from 'react';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { AiFillTag } from 'react-icons/ai';
import { MdHome, MdWork } from "react-icons/md";
import { FaRegBuilding, FaUser } from 'react-icons/fa';
import { GiShoppingCart, GiTicket } from 'react-icons/gi';
import { useNavigate, useLocation } from 'react-router-dom';
import { AttachmentIcon, ChatIcon, ExternalLinkIcon, StarIcon } from '@chakra-ui/icons';
import { Avatar, Flex, Text, Input, Center, Image } from '@chakra-ui/react';
import { getUser, setUser } from '../../services/auth';
import { ToastContainer, toast } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { changeImageprofile } from '../../services/api';

const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation(); // Para obter o caminho atual

    const user = getUser();
    const role = user.user_type;

    // Estado para armazenar a imagem do avatar
    const [avatarSrc, setAvatarSrc] = useState<string | ArrayBuffer | null>(
        'https://avataaars.io/?avatarStyle=Circle&topType=LongHairNotTooLong&accessoriesType=Round&hairColor=Platinum&facialHairType=BeardMajestic&facialHairColor=BrownDark&clotheType=BlazerSweater&eyeType=Hearts&eyebrowType=Angry&mouthType=Vomit&skinColor=Brown'
    );

    function getName() {
        if (user?.user_type === 'SUPER_ADMIN' || user?.user_type === 'ADMIN') {
            return user?.admin?.franchise?.company_name || user?.admin?.franchise?.name;
        }
        return user?.organizer?.company?.company_name || user?.organizer?.company?.name;
    }

    function handleNavigate(path: string) {
        navigate(path);
    }

    function handleLogout() {
        localStorage.clear();
        navigate('/');
        window.location.reload();
    }

    // Função para lidar com o upload da imagem do avatar
    const handleAvatarChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {

            const formData = new FormData();
            formData.append('image', file);

            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result !== undefined) {
                    setAvatarSrc(e.target.result as string | ArrayBuffer); // Faz o cast para string | ArrayBuffer
                }
            };
            reader.readAsDataURL(file);

            try {
                const {data} = await changeImageprofile(user?.id, formData)

                toast.success(data.message)
                user.profileImage = data.data.urlImage
                setUser(user)
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
                
            } catch (error) {
                console.log(error)
                toast.error('Ocorreu um error ao atualizar sua foto de perfil!')
            }
        }
    };

    const menuItems = [
        {
            path: '/category',
            icon: <AiFillTag />,
            label: 'Categorias',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/event',
            icon: <GiTicket />,
            label: 'Eventos Ativos',
            role: ['ORGANIZER', 'ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/orders',
            icon: <GiShoppingCart />,
            label: 'Pedidos',
            role: ['ADMIN', 'SUPER_ADMIN', 'ORGANIZER']
        },
        {
            path: '/highlights',
            icon: <StarIcon />,
            label: 'Destaques',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/notification',
            icon: <ChatIcon />,
            label: 'Notificações',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/report',
            icon: <AttachmentIcon />,
            label: 'Relatórios',
            role: ['ADMIN', 'SUPER_ADMIN', 'ORGANIZER']
        },
        {
            path: '/companies',
            icon: <MdHome />,
            label: 'Empresas',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/franchise',
            icon: <FaRegBuilding />,
            label: 'Franquias',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/users',
            icon: <FaUser />,
            label: 'Usuários',
            role: ['ADMIN', 'SUPER_ADMIN']
        },
        {
            path: '/logout',
            icon: <ExternalLinkIcon />,
            label: 'Sair',
            action: handleLogout,
            role: ['ADMIN', 'ORGANIZER', 'SUPER_ADMIN']
        }
    ];

    function renderMenuItems() {
        return menuItems.map((item, index) => {
            if (item.role.includes(role)) {
                const isActive = location.pathname === item.path;
                return (
                    <MenuItem
                        key={index}
                        icon={item.icon}
                        onClick={item.action ? item.action : () => handleNavigate(item.path)}
                        active={isActive}
                        style={{
                            backgroundColor: isActive ? '#3182ce' : '', // Cor do background quando ativo
                            color: isActive ? 'white' : '', // Cor do texto quando ativo
                        }}
                    >
                        {item.label}
                    </MenuItem>
                );
            }
            return null;
        });
    }

    return (
        <ProSidebar>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px'
                }}
            >
                <Image width={90} src='/logoIconHeader.png'/>
            </div>

            <Menu iconShape="circle">
                {renderMenuItems()}
            </Menu>

            <ToastContainer />
        </ProSidebar>
    );
};

export default Sidebar;
